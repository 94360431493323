import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PlayerComponent } from '../PlayerComponent';
import { Cart } from '../Cart';
import { useProduct } from '../ProductContext';
import { ProductSelector } from '../ProductSelector';

export const AppRoutes = () => {
  const { selectedProduct } = useProduct();

  return (
    <Routes>
      <Route path="/" element={<ProductSelector />} />
      <Route path="/builder" element={<PlayerComponent />} />
      <Route path="/builder/:configId" element={<PlayerComponent />} />
      <Route path="/cart" element={<Cart />} />
    </Routes>
  )
}