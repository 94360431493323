import React from 'react';
import logoImage from 'assets/images/morad-logo.png';
import './index.scss';
import { useProduct } from '../ProductContext';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../LanguageSelector';

const AppHeader =  () => {
  const { t } = useTranslation();

  const { selectedProduct } = useProduct();

  return (
    <header>
      <a href={'https://morad.pl/'} target={'_blank'}>
        <img src={logoImage} alt={'Moard Konfigurator'} className="logo"/>
      </a>
      <div className="product-selector">
        {selectedProduct && t(`products.${selectedProduct?.name}`)}
      </div>
      <div className="lang-selector">
        <LanguageSelector></LanguageSelector>
      </div>
    </header>
);
}

export default AppHeader