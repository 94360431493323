import Dexie, { type EntityTable } from 'dexie';

interface CartItem {
  id?: number;
  name: string;
  type: string;
  price: number;
  image?: any;
  doc?: any;
  configId?: string;
}

const db = new Dexie('CartItemsDatabase') as Dexie & {
  cardItems: EntityTable<
    CartItem,
    'id'
  >;
};

// Schema declaration:
db.version(1).stores({
  cardItems: '++id, name, type'
});

export type { CartItem };
export { db };