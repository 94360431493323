import React from 'react';
import './index.scss';
import { useProduct } from '../ProductContext';
import { Flex, Spin } from 'antd';

const AppHeader =  (props: any) => {

  const { isLoading } = props;

  return (
    <>
      {isLoading && (
        <div className="loading">
          <Flex align="center" gap="middle">
            <Spin size="large" />
          </Flex>
        </div>
      )}
    </>
  );
}

export default AppHeader