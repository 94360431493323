import './App.scss';
import React from 'react'
import AppHeader from './components/appHeader';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './components/AppRoutes';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "variantic-player": {
        "component-id": string;
        ref: any;
        width: string;
        height: string;
      };
    }
  }
}


function App() {

  return (
    <>
      {/*<BrowserRouter>*/}
      <BrowserRouter>
      <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#fec526',
          borderRadius: 2,
          colorBgContainer: '#ffffff',
        },
      }}
    >
        <div className="App">
          <div className="header">
            <AppHeader></AppHeader>
          </div>
          <div className="container">
            <AppRoutes></AppRoutes>
          </div>
        </div>
      </ConfigProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
