import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

const LANG_STORAGE_KEY = 'lang';

const lang = localStorage.getItem(LANG_STORAGE_KEY) || 'pl';

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    lng: lang || navigator.language.split('-')[0] || 'en',
    fallbackLng: 'en',
    backend: {
      loadPath: '/locales/{{lng}}/translations.json'
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    }
  });

export default i18n;