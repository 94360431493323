import React, { createContext, useContext, useState, useEffect } from 'react';
import { db } from '../../db';
import type { CartItem } from '../../db';
import { useTranslation } from 'react-i18next';

interface CartContextType {
  items: CartItem[];
  cartTotal: number;
  addItem: (item: Omit<CartItem, 'id'>) => Promise<void>;
  deleteItem: (id: number) => Promise<void>;
  getItemById: (id: number) => CartItem | null;
  cartItemCount: () => any;
  formattedCartTotals: () => string;
  formatAmount: (amount: number) => string;
}

interface Props {
  children: React.ReactNode;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<Props>= ({ children }) => {
  const [items, setItems] = useState<CartItem[]>([]);
  const [cartTotal, setCartTotal] = useState<number>(0);
  const { i18n } = useTranslation();


  const updateTotals = () => {
    const totals = items.reduce((acc, curr) => acc + curr.price, 0);
    setCartTotal(totals);
  }

  useEffect(() => {
    const fetchItems = async () => {
      const allItems = await db.cardItems.toArray();
      setItems(allItems);
    };

    fetchItems();
  }, []);

  useEffect(() => {
    updateTotals();
  }, [items])

  const addItem = async (item: Omit<CartItem, 'id'>) => {
    const id = await db.cardItems.add(item);
    const newItem = { id, ...item };
    setItems([...items, newItem]);
  };

  const deleteItem = async (id: number) => {
    await db.cardItems.delete(id);
    setItems(items.filter(item => item.id !== id));
  };

  const getItemById = (id: number): CartItem | null => {
    return items.find(item => item.id === id) || null;
  }

  const cartItemCount = () => {
    return items.length;
  };

  const formattedCartTotals = (): string => {
    const lang = i18n.language;
    return new Intl.NumberFormat(lang, { style: 'currency', currency: 'PLN' }).format(cartTotal);
  }

  const formatAmount = (amount: number): string => {
    const lang = i18n.language;
    return new Intl.NumberFormat(lang, { style: 'currency', currency: 'PLN' }).format(amount);
  }

  return (
    <CartContext.Provider value={{ items, cartTotal, addItem, deleteItem, cartItemCount, formattedCartTotals, getItemById, formatAmount }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};