import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Badge, Card, Col, Image, Row, Tag } from 'antd';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { useProduct } from '../ProductContext';
import { useCart } from '../CartContext';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export const ProductSelector = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { allProducts, selectProduct } = useProduct();
  const { cartItemCount, formattedCartTotals } = useCart();

  const onClickHandle = (id: number) => {
    selectProduct(id);
    navigate("/builder");
  }

  const goToCart = () => {
    navigate("/cart");
  }

  return (
    <>
    <div className="product-selector">
      <h1 style={{marginBottom: 100}}>{t('choose_product')}</h1>
      <Row gutter={16} justify="center">
        {
          allProducts.map((product: any, index: number) => {
            return (
              <Col span={6} key={'product_' + index}>
                <Card
                  hoverable
                  style={{height: 300}}
                  className={'product-item'}
                  onClick={() => onClickHandle(product.id)}
                  cover={<img alt="example" src={product.img}/>}>
                  <Card.Meta
                    title={t(`products.${product.name}`)}
                  />
                </Card>
              </Col>
            )
          })
        }
      </Row>
    </div>
    {cartItemCount() > 0 && (
      <>
      <div className="price cart-total">
        <span>{t('your_shopping_cart')}</span>
        {formattedCartTotals()}
      </div>
      <div className="add-to-cart"
           onClick={goToCart}>
        <Badge count={cartItemCount()}>
          <Avatar
            style={{backgroundColor: 'transparent'}}
            size="large"
            shape="square" icon={<ShoppingCartOutlined/>}/>
        </Badge>
      </div>
      </>
  )
}
</>)
}